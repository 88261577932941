.App {
  font-family: sans-serif;
  text-align: center;
}

.progress {
  background-color: #f5f5f5;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 30px;
  height: 230px;
  display: inline-block;
  margin-right: 0px !important;
}

.progress {
  height: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  background-color: #eaa745;
  box-shadow: none;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #eaa745;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.rTime, .nrTime{background: #efefef; padding: 4px 8px; cursor: pointer;/* color: rgb(234, 167, 69); */}
.rTime.active, .nrTime.active {background:rgb(234, 167, 69); font-weight: 600; color: #fff;}
.rTimeBox {display: flex; justify-content: space-between; margin-top: 6px;}
.rdt input.form-control:disabled { background: #efefef; cursor: not-allowed;}
.tabelName {color: #000; font-size: 20px; font-weight: 400; margin: 20px 0; display: inline-block;}
.deviceDisclaimer{text-align: center; margin: 0; border-top: 1px solid #efefef; padding: 10px; font-weight: 400; margin-top: 20px;}
.deviceDisclaimer:before, .deviceDisclaimer:after {content: ''; height: 10px; width: 10px; background: #ed193b; display: inline-block; border-radius: 10px; margin: 0 6px;}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.flexCenter {display: flex; align-items: center;}
.heartBeatAnimation{animation: heartbeat 1s infinite; width: 30px; display: inline-block}
.offlineHeart:before {content: ''; border: 1px solid; transform: rotate(45deg); height: 29px; display: inline-block; top: 2px; position: relative; left: 14px;}
.dot {width: 10px; height: 10px; border-radius: 10px; display: inline-block; margin: 0 4px;}
.dot.red {background-color: #ed193b;}
.dot.green {background-color: green;}
.dot.yellow {background-color: #ff8300;}
.font-it {font-style: italic;}
.noGuestFound {text-align: center; font-size: 22px; font-weight: 500;}
.noContentFound {font-size: 22px; font-weight: 500; width: 100%; background: #fff; padding: 20px; border-radius: 6px; border: 1px solid #e2e2e2; text-align: center;}
.guestDisclaimer {border: 2px solid #efefef; margin: 25px; border-radius: 6px; padding: 8px; box-shadow: 5px 7px #efefef;}
.dateDisclaimer {font-size: 12px; margin: 8px 0; color: #222; font-weight: 500;}
.tac {text-align: center;}
.inviteBtnHdr {margin: 15px 5px; float: right;}
.singupEmailField { padding-top: 7px;margin: 0 0 17px 0;}